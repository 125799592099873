
import { icons } from "./Icons.js";
import { Box } from "./Box.js";
import CloudWord from "./CloudWord.js";
import { useState, useEffect } from 'react';

import { Navbar, Dropdown, Button, Link, Text, createTheme, NextUIProvider, Switch, useTheme, Tooltip} from '@nextui-org/react';
import useDarkMode from 'use-dark-mode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun, faCompassDrafting } from '@fortawesome/free-solid-svg-icons'
import { faReddit } from '@fortawesome/free-brands-svg-icons'


const lightTheme = createTheme({
  type: 'light',
})

const darkTheme = createTheme({
  type: 'dark'
})


const MoonIcon = <FontAwesomeIcon icon={faMoon} />
const SunIcon = <FontAwesomeIcon icon={faSun} />
const CompassDraftingIcon = <FontAwesomeIcon icon={faCompassDrafting} />
const RedditIcon = <FontAwesomeIcon icon={faReddit} size="xl" />


function App() {

  const darkMode = useDarkMode(false);
  const { type, isDark } = useTheme();

  const [selectedSubReddit, setSelectedSubReddit] = useState('worldnews');

  return (
    <NextUIProvider theme={darkMode.value ? darkTheme : lightTheme}>
      <Box
        css={{
          maxW: "100%"
        }}
      >
        <Navbar isBordered variant="sticky">
          <Navbar.Brand>
            {RedditIcon}
            <Tooltip
              color="invert"
              content="Reddit Trending Word"
              placement="right"
            >
            <Text b color="inherit" hideIn="xs" css={{ pl: 10 }}>
              RTW
            </Text>
            </Tooltip>   
          </Navbar.Brand>
          <Navbar.Content
            enableCursorHighlight
            activeColor="secondary"
            variant="underline"
          >
            <Dropdown isBordered>
              <Navbar.Item>
                <Dropdown.Button
                  auto
                  light
                  css={{
                    px: 0,
                    dflex: "center",
                    svg: { pe: "none" },
                  }}
                  iconRight={icons.chevron}
                  ripple={false}
                >
                  Community
                </Dropdown.Button>
              </Navbar.Item>
              <Dropdown.Menu
              disallowEmptySelection
              selectionMode="single"
                selectedKeys={selectedSubReddit}
                onAction={setSelectedSubReddit}
                css={{
                  $$dropdownMenuWidth: "340px",
                  $$dropdownItemHeight: "70px",
                  "& .nextui-dropdown-item": {
                    py: "$4",
                    // dropdown item left icon
                    svg: {
                      color: "$secondary",
                      mr: "$4",
                    },
                    // dropdown item title
                    "& .nextui-dropdown-item-content": {
                      w: "100%",
                      fontWeight: "$semibold",
                    },
                  },
                }}
              >
                <Dropdown.Item
                  key="worldnews"
                  showFullDescription
                  description="A place for major news from around the world, excluding US-internal news."
                >
                  r/worldnews
                </Dropdown.Item>
                <Dropdown.Item
                  key="news"
                  showFullDescription
                  description="The place for news articles about current events in the United States and the rest of the world. Discuss it all here."
                >
                  r/news
                </Dropdown.Item>
                <Dropdown.Item
                  key="politics"
                  showFullDescription
                  description="/r/Politics is for news and discussion about U.S. politics."
                >
                  r/politics
                </Dropdown.Item>
                <Dropdown.Item
                  key="wallstreetbets"
                  showFullDescription
                  description="Like 4chan found a Bloomberg Terminal"
                >
                  r/wallstreetbets
                </Dropdown.Item>
                <Dropdown.Item
                  key="gaming"
                  showFullDescription
                  description="A subreddit for (almost) anything related to games - video games, board games, card games, etc. (but not sports)."
                >
                  r/gaming
                </Dropdown.Item>
                <Dropdown.Item
                  key="Music"
                  showFullDescription
                  description="The musical community of reddit"
                >
                  r/Music
                </Dropdown.Item>
                
                
                <Dropdown.Item
                  key="movies"
                  showFullDescription
                  description="The goal of /r/Movies is to provide an inclusive place for discussions and news about films with major releases. "
                >
                  r/movies
                </Dropdown.Item>
                <Dropdown.Item
                  key="CryptoCurrency"
                  showFullDescription
                  description="The leading community for cryptocurrency news, discussion, and analysis."
                >
                  r/CryptoCurrency
                </Dropdown.Item>
                <Dropdown.Item
                  key="CasualUK"
                  showFullDescription
                  description="UK based subreddit for non-political news, commentary and discussion."
                >
                  r/CasualUK
                </Dropdown.Item>
                <Dropdown.Item
                  key="AskUK"
                  showFullDescription
                  description="The #1 subreddit for Brits and non-Brits to ask questions about the United Kingdom, its culture, and mundane banalties of British existence."
                >
                  r/AskUK
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Content>
          <Navbar.Content>
            <Navbar.Item>
              {
                darkMode.value ?
                  (<Button
                    auto
                    light
                    icon={SunIcon}
                    onClick={() => darkMode.toggle()}
                  />) :
                  (<Button
                    auto
                    light
                    icon={MoonIcon}
                    onClick={() => darkMode.toggle()}
                  />)
              }
            </Navbar.Item>
            {/* <Navbar.Item>
              <Button auto flat as={Link} href="#" icon={CompassDraftingIcon} css={{ px: 15 }}>
              </Button>
            </Navbar.Item> */}
          </Navbar.Content>
        </Navbar>
        <CloudWord subReddit={selectedSubReddit}></CloudWord>
      </Box>

    </NextUIProvider>

  );
}

export default App;




