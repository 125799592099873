import ReactWordcloud from 'react-wordcloud';
import axios from "axios";
import { useState, useEffect } from 'react';
import { Container, Loading, Row, Link, Text, Card, Avatar } from '@nextui-org/react';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'

TimeAgo.addDefaultLocale(en)

const options = {
    enableTooltip: false,
    deterministic: true,
    enableOptimizations: true,
    fontFamily: "impact",
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 1,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 2000
};

var data = JSON.stringify({
    "collection": "worldnews_group",
    "database": "rtw_db",
    "dataSource": "Cluster0",
    "projection": {
        "_id": 1
    }
});

const CloudWord = (props) => {

    const [wordList, setWordList] = useState();

    const [latestTitle, setLatesTitle] = useState();
    const [lastUpdateUtcDate, setLastUpdateUtcDate] = useState(1);
    const [prevSubReddit, setPrevSubReddit] = useState("");


    const [counter, setCounter] = useState(0);

    const subRedditLink = "https://www.reddit.com/r/" + props.subReddit + "/new/";

    useEffect(() => {

        const timer = setInterval(() => {
            setCounter(prevTime => prevTime + 1);
        }, 10000);
        return () => {
            clearInterval(timer);
        };

    }, []);


    useEffect(() => {

        const fetchData = async (lastUpdateUtcDate) => {

            lastUpdateUtcDate = lastUpdateUtcDate / 1000;

            
            // console.log(props.subReddit);

            // console.log(lastUpdateUtcDate);

            const resultResponse = await axios({
                method: 'get',
                url: 'https://agtgip8vh0.execute-api.eu-west-2.amazonaws.com/prod?subReddit=' + props.subReddit + '&lastUpdateUtcDate=' + lastUpdateUtcDate,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/ejson'
                },
                data: data
            });

            if (resultResponse.data[0] !== 'UNCHANGED') {
                // console.log(resultResponse.data[0]);
                setLastUpdateUtcDate(resultResponse.data[0] * 1000);
                let latestPost = resultResponse.data[1];
                setLatesTitle(latestPost['latestTitle']);
                setWordList([]);
                setWordList(resultResponse.data[2]);
            }
        }

        if(prevSubReddit !== props.subReddit){
            setLastUpdateUtcDate(1);
            setPrevSubReddit(props.subReddit);
        }

        fetchData(lastUpdateUtcDate)

    }, [counter, props.subReddit]);

    return (
        <div>
            {wordList?.length > 0 ? (
                <div>
                    <ReactWordcloud options={options} maxWords="200" words={wordList} />

                    <Container>
                        <Row css={{ py: 5 }}>
                            <Link block color="primary" href={subRedditLink} target="_blank">
                                <Text
                                    size={25}
                                    weight="bold"
                                >
                                    r/{props.subReddit}
                                </Text>
                            </Link>
                        </Row>

                        <Row css={{ pb: 10 }}>
                            <Card variant="bordered">
                                <Card.Header>
                                    <Avatar
                                        src="https://upload.wikimedia.org/wikipedia/commons/4/41/Red_circle.gif"
                                        size="sm"
                                    />
                                    <Text b css={{ pl: 10 }}>Live - Posted <ReactTimeAgo date={lastUpdateUtcDate} locale="en-US"/></Text>
                                </Card.Header>
                                <Card.Divider />
                                <Card.Body>
                                    <Text>{latestTitle}</Text>
                                </Card.Body>
                            </Card>
                        </Row>

                    </Container>
                </div>
            ) : (
                <Container>
                    <Row justify="center" align="center" css={{ pt: 20 }}>
                        <Loading>Loading</Loading>
                    </Row>
                </Container>

            )}

        </div>
    );
}

export default CloudWord;